import React from "react";
import { Button } from "reactstrap";
import { Drawer, Form, Input, Col, Row, Spin, Select } from "antd";
import LikeIcon from "./assets/images/thumbs-up.png";
import CenterLogo from "./assets/images/Web3Ventures.svg";
import json from "./countryCode.json";
import axios from "axios";
import "./App.css";
const { TextArea } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 24 },
};
function App() {
  const [form] = Form.useForm();
  const [visible, setVisible] = React.useState(false);
  const [hideForm, setHideForm] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [fullNameError, setFullNameError] = React.useState("");
  const [phoneNoError, setPhoneNoError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
    setHideForm(false);
  };
  let countryList = [];
  const submitMessage = (formData) => {
    let data = {
      company_name: formData.company_name,
      email: formData.email,
      full_name: formData.full_name,
      message: formData.message,
      phone_number: formData.prefix + formData.phone_number,
    };
    if (formData.full_name === undefined) {
      setFullNameError("Please enter full name.");
    }
    if (formData.phone_number === undefined) {
      setPhoneNoError("Please enter phone number.");
    }
    if (formData.email === undefined) {
      setEmailError("Please enter email address.");
    } else if (
      fullNameError !== undefined &&
      phoneNoError !== undefined &&
      emailError !== undefined
    ) {
      setIsLoading(true);
      axios
        .post(`https://api.contactif.ai/ventures-contact-us/`, data)
        .then((res) => {
          setHideForm(true);
          setIsLoading(false);
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };
  React.useEffect(() => {
    let test = json.countries;
    {
      test.map((item, index) => {
        return countryList.push(item.code);
      });
    }
  }, []);
  const getTags = () => {
    let tags = json.countries;
    let children = [];
    for (let i = 0; i < tags.length; i++) {
      children.push(
        <Select.Option
          key={`${tags[i].code}`}
          title={`${tags[i].code}`}
          autoFocus="autofocus"
        >{`${tags[i].code}`}</Select.Option>
      );
    }
    return children;
  };

  const changeFirstName = (e) => {
    if (e.target.value.length == 0) {
      setFullNameError("Please enter first name.");
    }
    if (e.target.value.match(/^[a-zA-Z]+( [a-zA-Z]+)*$/im)) {
      setFullNameError("");
    } else if (e.target.value === "") {
      setFullNameError("");
    } else {
      setFullNameError("Please enter valid first name.");
    }
  };
  const changeEmail = (e) => {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value.length === 0) {
      setEmailError("Please enter email.");
    }
    if (!regEmail.test(e.target.value)) {
      setEmailError("Please enter valid email.");
    } else if (e.target.value === "") {
      setEmailError("");
    } else {
      setEmailError("");
    }
  };
  const changePhoneNo = (e) => {
    if (e.target.value.length > 0) {
      setPhoneNoError("");
    }
    if (
      e.target.value.match(
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,14}$/im
      )
    ) {
      setPhoneNoError("");
    } else if (e.target.value === "") {
      setPhoneNoError("");
    } else {
      setPhoneNoError("Please enter valid phone no");
    }
  };
  return (
    <div className="app">
      <div className="lower-background"></div>

      <div className="nav-row">
        {" "}
        <Button
          type="primary"
          onClick={showDrawer}
          className="contact-us-button"
        >
          Contact Us
        </Button>
      </div>
      <div className="logo-div">
        {" "}
        <img src={CenterLogo} className="center-logo" />
        {/* <CenterLogo /> */}
        {/* <CenterLogo /> */}
      </div>
      <p className="logo-line">
        Building next generation compute infrastructure on crypto rails
      </p>
      <Drawer
        placement="right"
        onClose={onClose}
        visible={visible}
        className="side-bar"
      >
        {isLoading && isLoading == true ? (
          <Spin className="loader" size="large" />
        ) : (
          ""
        )}
        {hideForm === false ? (
          <div className="main-row">
            <div className="row-1">
              <span className="contact">Contact</span>
              <br />
              <br />

              <span className="line1">Interested in partnering with us?</span>
              <br />
            </div>
            <div className="row-2">
              <span className="line2">
                Simply fill out this form or{" "}
                <a href="mailto:example@example.com" target="_blank">
                  {" "}
                  send us an email.{" "}
                </a>
              </span>
            </div>
            <Row>
              <Form
                form={form}
                name="nest-messages"
                onFinish={submitMessage}
                wrapperCol={{ lg: 24 }}
                className="contact-form"
                colon={false}
              >
                <Row>
                  <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    <Form.Item
                      name={"full_name"}
                      label="Your full name*"
                      className="input-field"
                    >
                      <Input
                        placeholder="Enter your full name"
                        onChange={changeFirstName}
                        autoComplete="offf"
                      />
                    </Form.Item>
                    <span style={{ color: "red" }}>{fullNameError}</span>
                  </Col>
                  <Col md={2} lg={2} xl={2}></Col>
                  <Col xs={24} sm={24} md={11} lg={11} xl={11}>
                    {" "}
                    <Form.Item
                      name={"company_name"}
                      label="Your company name (optional)"
                      className="input-field"
                    >
                      <Input
                        placeholder="Enter your company name"
                        autoComplete="of"
                      />
                    </Form.Item>
                  </Col>
                </Row>{" "}
                <Form.Item
                  name={"email"}
                  label="Your email address*"
                  className="input-field"
                >
                  <Input
                    maxLength={500}
                    placeholder="Enter your email adress"
                    onChange={changeEmail}
                    autoComplete="offff"
                  />
                </Form.Item>
                <span style={{ color: "red" }}>{emailError}</span>
                <Form.Item
                  name={"phone_number"}
                  label="Phone number (with country code)*"
                  className="input-field"
                >
                  <Input
                    maxLength={500}
                    placeholder="124 7458645"
                    onChange={changePhoneNo}
                    autoComplete="offff"
                  />
                  {/* <Input
                    addonBefore={
                      <Form.Item name="prefix" noStyle>
                        <Select
                          style={{
                            width: 70,
                          }}
                        >
                          {getTags()}
                        </Select>
                      </Form.Item>
                    }
                    style={{ width: "100%" }}
                    placeholder="124 7458645"
                    onChange={changePhoneNo}
                    autoComplete="off"
                  /> */}
                </Form.Item>
                <span style={{ color: "red" }}>{phoneNoError}</span>
                <Form.Item
                  name={"message"}
                  label="Your message (optional)"
                  className="text-area-field"
                >
                  <TextArea
                    style={{ width: "100%" }}
                    placeholder="Write your message or comment here..."
                  />
                </Form.Item>
                <Form.Item
                  name="sendButton"
                  wrapperCol={{ ...layout.wrapperCol }}
                  className="send-button-row"
                >
                  <span>
                    <Button type="submit" className="send-button">
                      Send
                    </Button>
                  </span>
                </Form.Item>{" "}
              </Form>
            </Row>
          </div>
        ) : (
          <div className="msg-row">
            <img src={LikeIcon} className="like-img" />
            <br />
            <span className="line-1">Congrats</span>
            <br />
            <span className="line-2">
              Your request has been sent successfully. You will be notify in
              shortly.
            </span>
            <br />
            <Button className="close-button" onClick={onClose}>
              Close
            </Button>
          </div>
        )}
      </Drawer>
    </div>
  );
}

export default App;
