import React, { Component } from "react";
import Js from './lightening.js'
var __html = require('./lightening.html.js');
var template = { __html: __html };

class ScreenShare extends Component {
    render() {
        return (
            <div dangerouslySetInnerHTML={template} />
        );
    }
}
export default ScreenShare;
