module.exports = `
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>

    <link rel="stylesheet" href="./lighteningStyles.css">
</head>

<body>
    <div class="ui">
        <p class="zoom"><span class="zoom zoomin">+</span><span class="zoom zoomout">-</span></p>
        <p class="zoomlevel"><span class="percent">100</span> % - (<span class="width"></span>px)(<span
                class="height"></span>px)</p>
        <p>Dead: <span class="dead">0</span></p>
        <p>Alive: <span class="alive">0</span></p>
        <p>Drawn: <span class="drawn">0</span></p>
        <p><span class="fps">0</span> FPS</p>
        <a class="save" href="" download="capture.png">Save</a>
    </div>
</body>

</html>`;